import axios from "axios";

export type AppData = {
  appRoute: string | string[];
  main: string;
  requiresLogin?: boolean;
}

export type AppsData = {
  [name: string]: AppData
}


export async function getAppsData() {
  return (await axios.get<AppsData>(__APPS_URL__)).data;
}
